import React from 'react';
import { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import Media from 'react-bootstrap/Media'
import styled from 'styled-components';
import taustaKuva from '../kuvat/taustakuva.jpg';
import Image from 'react-bootstrap/Image';

const Styles = styled.div`
  .jumbo {
    background: url(${taustaKuva}) no-repeat fixed center;
    background-size: cover;
    color: #efefef;
    height: 100;
    position: relative;
    margin-bottom: 0px;
    z-index: -2;
    padding-top: -100%;
  }
  .overlay {
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1; 
  }
`;

export const Jumbotron = () => (
  <Styles>
    <Jumbo fluid className="jumbo">
      <div className="overlay"></div>
      <Container>
        <h1>Tervetuloa</h1>
        Tämän sivuston aiheena oli Ilomantsissa heinä-elokuussa 1944 käydyt jatkosodan viimeiset suuret taistelut. Tein sen itse alusta loppuun kurssiprojektina web -ja mobiilikäyttöliittymät kurssia varten, ja on sen jälkeen
        toiminut lähinnä testisivustona itselleni mm. erilaisten rajapintojen kokeiluun (esimerkkinä Goodreads - välilehti). Alkuperäinen teksti jatkuu: 
          Hätiin koottu divisioonan vahvuinen improvisoitu tilapäisyhtymä Ryhmä Raappana pysäytti Pohjois-Karjalaan 
          tunkeutuneiden kahden neuvostodivisioonan etenemisen, motitti ja löi – ja lähes täysin tuhosi – ne kymmenpäiväisissä korpitaisteluissa. 
          Saarretut neuvostojoukot saivat apuvoimia, minkä vuoksi mottien tuhoaminen jäi suomalaisilta kesken. Raappanan joukot olivat pakotettuja 
          torjumaan avustushyökkäyksiä, ja puna-armeijan joukkojen rippeet pääsivät murtautumaan omiensa yhteyteen. Siitä huolimatta Ryhmä Raappana 
          sai runsaan sotasaaliin, ja se työnsi vihollisjoukot 30 kilometriä taaksepäin Moskovan rauhan rajan taakse. Sivujen artikkeleissa on merkitty
          lähdeaineisto, tilanne ennen taisteluita, joukot ja voimasuhteet, osapuolten suunnitelmat ja aikeet lähinnä suomalaisesta näkökulmasta sekä tietysti taistelujen kulun. 
          Eri sivuilta tulet löytämään karttoja taisteluista kuvauksineen (Sijainnit - sivu), tietoja ja kuvia avainhenkilöistä 
          (avainhenkilöt – sivu), muutamia kuvia (Galleria -sivu), ja Yhteystiedot.
          <br></br>
          <br></br>
          Isto Päivinen
          <br></br>
          Karelia-ammattikorkeakoulu
          <br></br><br></br>
          <h3>Yleistä:</h3>
          Kesäkuun alussa 1944 II Armeijakunta (II AK) oli puolustuksessa Maaselän kannaksella, 
          Äänisen pohjoispuolella – kohtuullisen hyvissä asemissa ja joukkojakin oli kohtalaisen mukavasti – jopa reservejä.
          Venäjä aikoi kuitenkin valloittaa koko Suomen aloittaen 9.6.1944 Neljännen Strategisen Suurhyökkäyksen Suomea vastaan. 
          Ylipäällikkö marsalkka Mannerheim joutui vetämään joukkoja sekä Maaselän kannakselta, että Aunuksen kannakselta tärkeimpään suuntaan
          Karjalan Kannakselle, sillä siellä oli tapahtunut Pääasemassa vakava läpimurto Valkeasaaressa ja toinen heti perään VT-asemassa Kuuterselässä.
          Siksi II AK etevälle komentajalle kenraalimajuri Einar Mäkiselle ei jäänyt liiemmälti joukkoja puolustukseen, 
          eikä käskettyyn viivytykseen. Onneksi kenraali Mäkinen osasi viivytystaistelun hienoudet.
          Kenraali Mäkisen ongelmana eivät olleet pelkästään vastassa olevat vihollisjoukot, vaan myös kenraaliluutnantti 
          Paavo Talvelan komennossa olevat oikealla sivustalla olevat omat Aunuksen Ryhmän joukot. Kenraali Talvela nimittäin oli etevä 
          komentaja hyökkäyksessä ja puolustuksessa – 
          vaan ei hallinnut viivytyksen perusteita. Siksi II AKn oikea sivusta oli uhattuna. 
          <br></br><br></br>
         <h3>Ilomantsi</h3>
         Heinäkuun 1944 lopulla Ilomantsia lähestyi Porajärven suunnasta kaksi kenraaliluutnantti Filipp D.
Gorolenkon komentamaan 32. Armeijaan kulunutta neuvostodivisioonaa käsittänyt voimaryhmä, jonka
tehtävänä oli edetä Laatokan rantaan suomalaisten VI Armeijakunnan selustaan. Tämä tapahtui vaiheessa,
jolloin tilanne muualla oli rauhoittumassa ja rintamat saatu vakautettua. Tilanne näytti uhkaavalta, kun
neuvostojoukkojen kärki oli ylittänyt Moskovan rauhan rajan 21.7. Longonvaarassa, ja ne jatkoivat
etenemistään kohti Ilomantsin kirkonkylää. Pohjoisempi neuvostoyhtymä, eversti Vasili I. Zolotarjovin
176. Divisioona, eteni rykmentit rinnakkain, mutta eteläisempi divisioona, kenraalimajuri Nikolai A.
Tšernuhan 289. Divisioona, liikkui syvyyteen porrastettuna ja sillä oli käytössään runsaasti reservejä.
Molemmat yhtymät olivat kuluneita. Vaikka neuvostoyhtymillä ei ollut yhtenäistä johtoa, ne aiheuttivat
vakavan uhkan II Armeijakunnan sivustaa ja 21. Prikaatin selustaa vastaan.
<p></p> Voimaryhmän eteneminen
vaaransi myös Rukajärven suunnan selustayhteydet. Mikäli kahden tien suunnassa etenevät hyökkäyskiilat
olisivat onnistuneet yhtymään Ilomantsissa, hyökkääjä olisi päässyt käsiksi Ilomantsin länsipuoliseen
tieverkkoon. Seuraukset olisivat voineet olleet katastrofaaliset. Juri Kilin on todennut, että operaation
päätavoitteena oli pakottaa Suomi rauhaan ja saada se antautumaan ehdoitta.
Kenraalimajuri Einar Mäkisen II Armeijakunta oli käynyt yli kuukauden ajan kiivaita viivytystaisteluita
Maaselän kannakselta alkaen. Heinäkuun lopulla Ilomantsissa olivat neuvostodivisioonia vastassa vain
takarajalleen vetäytynyt, viivytystaistelusta uupunut ja kulunut eversti Torvald Ekmanin noin 7 000
miehen 21. Prikaati ja everstiluutnantti Yrjö Seppälän Rajajääkäripataljoona 3. Noin 6000 miehen
vahvuinen eversti Urho Tähtisen komentama Ratsuväkiprikaati sai heinäkuun 24. päivä päämajalta käskyn
siirtyä Viipurinlahdelta Ilomantsiin.
<p></p>
Keskeisen roolin operaatiossa sai 14. Divisioonan joukoista irrotettu kaksi pataljoonaa käsittänyt
Osasto Partinen (everstiluutnantti Väinö Partisen komennossa). Ryhmä Raappanan joukkoja
vahvennettiin vielä elokuun alussa mm. Jalkaväkirykmentti 33:n II pataljoonalla. Tiedustelutietoa
johtamisen taustaksi saatiin myös Mannerheim-ristin ritarien Heikki Nykäsen ja Onni Määttäsen 
3
partioilta, ja olihan siellä myös Lauri Törni 1. Divisioonan jääkärikomppanian kanssa. Hänen
alaisenaan palveli pikakiväärimiehenä Mauno Koivisto.
Operaation alkuvaiheessa voimasuhteet olivat lähes tasan: suomalaisilla oli hieman enemmän miehiä
(13 000: 11000), mutta puna-armeijalla oli enemmän tulivoimaa. Ryhmä Raappanan taistelujaotus
toteutettiin aluksi selkeällä tavalla – esim. tykistövoima jaettiin tarkoituksenmukaisesti tukemaan
jalkaväkeä. Alkuvaiheessa käytössä oli 3 kevyttä patteristoa, yksi raskas patteristo sekä kuusitykkinen
ratsastava patteri. Taistelujen loppuvaiheessa Ryhmä Raappana oli saanut kaksi raskasta patteristoa lisää,
kaksi raskasta kranaatinheitinkomppaniaa sekä panssarintorjuntavoimaa. Myöhemmin alistuksia oli niin
paljon, että johtosuhteet olivat sanalla sanoen sekavat. Ryhmä Raappanalla ei ollut varsinaisia
huoltojoukkoja, mikä oli paha puute pitkien etäisyyksien harvateisessä ja muutenkin vaikeakulkuisessa
vaaramaastossa.
<p></p>
<Media>
<a href="https://fi.wikipedia.org/wiki/Erkki_Raappana">
  <Image
    width={64}
    height={84}
    className="mr-3"
    src="https://i.gyazo.com/3cc2b3b861261dc89f1b3b05ef53755d.png"
    alt="Generic placeholder"
  />
</a>
  <Media.Body>
    <h5>Erkki Raappana</h5>
    <p>
      kenraalimajuri Erkki Johannes Raappana, tunnetaan myös nimellä Korpikenraali. 
      Hän toi Suomelle myös viimeisen suuren torjuntavoiton Ilomantsissa vuoden 1944 elokuussa, 
      jolloin hänen joukkonsa saarrostivat ja tuhosivat Ilomantsin korpitaisteluissa kaksi puna-armeijan divisioonaa. 
    </p>
  </Media.Body>
</Media>
Rukajärvellä 14. Divisioonaa komentanut Raappana nautti ylipäällikön luottamusta ja hänellä oli
erinomainen paikallistuntemus, jonka hän oli hankkinut toimiessaan maailmansotien välillä lähes kaksi
vuosikymmentä Joensuun rajavartioston komentajana, ja pitäjä oli ollut hänen metsästysmaastoaan.
Raappana sai käskyn lyödä Ilomantsin suuntaan etenevät vihollisvoimat ja poistaa suunnalle syntynyt
sivustauhka. Hänelle jäi melko lailla vapaat kädet päättää tehtävän toteutuksesta. Raappana ei ehtinyt
pidempään pohtia suoritusvaihtoehtoja, koska tilanteenarviointiin ei jäänyt paljon aikaa. Raappana kertoi
tehneensä päätöksen intuition pohjalta. 

          <br></br>
          <p></p>
          <p className ="text-muted">
    Lisätietoja/lähteet: <br></br>
    Matti Koskimaa, Suomen kohtalon ratkaisut, 2013 <br></br>
    Pasi Tuunainen, Voitto korvessa – Ilomantsin mottitaistelut kesällä 1944, 2014
    </p> 
        
      </Container>
    </Jumbo>
  </Styles>
)