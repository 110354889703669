import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image';

//todo palautelomake
class Palaute extends React.Component {

    render() {
        return (
            <div className="text-center">
              <br></br>
<Container className="text-center">
  <h1>Yhteystiedot:</h1>
Isto Päivinen
<br></br>
[Sähköposti]
<br></br>
Karelia Ammattikorkeakoulu
<br></br>
<a href="https://github.com/isp96">
<Image src="https://i.gyazo.com/ab7e10cbe695095bd171c876b2eb09de.png"/>
</a>
</Container>
            </div>
        );
    }
}

export default Palaute;