import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import CardDeck from 'react-bootstrap/CardDeck'
import Accordion from 'react-bootstrap/Accordion'
import { MDBContainer, MDBRow, MDBCol, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import taustaKuva from '../kuvat/taustakuva.jpg'

const styles = {
  grid: {
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: 2,
    backgroundImage: taustaKuva,
    marginBottom: 5
  },
  row: {
    margin: 'auto',
    marginTop: 15,
    // marginRight: 5,
    // height: '70%',
    width: '90%',
    marginBottom: 75,
    padding: 10
  },
  col: {
    paddingLeft: 5,
    paddingRight: 5
  },
  teksti: {
    marginTop: 5,
    marginBottom: 10
  }

};


class Avainhenkilot extends React.Component {
  state = {
    items: {
      default: "1",
    }
  };

  togglePills = (type, tab) => e => {
    e.preventDefault();
    if (this.state.items[type] !== tab) {
      let items = { ...this.state.items };
      items[type] = tab;
      this.setState({
        items
      });
    }
  };
  render() {
    return (
      <div style={styles.grid}>
         <style>{'body { background-color: grey; }'}</style>
        <CardDeck style={styles.row}>
          <Card>
            <Card.Img variant="top" src="../kuvat/kuva1.png" />
            <Card.Body>
              <Card.Title>Raappanat</Card.Title>
              <Card.Text>
                Kuvassa kenraalimajuri Erkki Raappana ja hänen poikansa, Ermo Raappana.
      </Card.Text>
              <MDBContainer className="mt-1" style={styles.grid}>
                <MDBRow>
                  <MDBCol md="12">
                    <MDBNav className="mt-0 nav-pills">
                      <MDBNavItem>
                        <MDBNavLink link to="#" active={this.state.items["default"] === "1"} onClick={this.togglePills("default", "1")} >
                          Tausta
                  </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink link to="#" active={this.state.items["default"] === "2"} onClick={this.togglePills("default", "2")} >
                          Talvisota
                  </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink link to="#" active={this.state.items["default"] === "3"} onClick={this.togglePills("default", "3")} >
                          Jatkosota
                  </MDBNavLink>
                      </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent activeItem={this.state.items["default"]}>
                      <MDBTabPane tabId="1">
                        <p style={styles.teksti}>
                          Erkki Johannes Raappana syntyi 2. kesäkuuta 1893 Oulujoella.
                          Sotilasoppinsa hän sai Saksan sotakoulun B-kurssilla Libaussa vuonna 1917 ja Sotakorkeakoulun komentajakurssilla vuonna 1926.
                          Hän osallistui Kuninkaallisen Preussin jääkäripataljoona 27:n riveissä Misse-joen, Riianlahden ja Aa-joen taisteluihin Venäjää vastaan.

                  </p>
                      </MDBTabPane>
                      <MDBTabPane tabId="2">
                        <p style={styles.teksti}>
                          Hieman talvisodan alkamisen jälkeen everstiksi ylennetty Raappana nimitettiin Pohjois-Karjalan ryhmän komentajaksi ja sai vastuun
                          Lieksa–Kuhmo rintamaosan puolustuksesta. Raappana veti heti kenttävartionsa taaemmaksi ja sijoitti kaksi pataljoonansa telttoihin korpeen.
                          Puna-armeijan vahvennetun rykmentin ensimmäinen hyökkäys oli isku tyhjään. Raappana lähetti II pataljoonansa katkomaan vihollisen selustayhteyksiä.
                          Viholliskomentaja pelästyi Raappanan koukkauksesta niin, että veti joukkonsa rajan taakse jo ennen vuoden vaihdetta.
                          Venäläiset hyökkäysvaunut yllättivät Raappanan, tankit olivat ennen näkemättömiä taisteluaseita. Raappanan kehotuksesta partiopojat
                          valmistivat bensiinillä ja tervalla täytettyjä polttopulloja. Näitä ruvettiin myöhemmin kutsumaan Molotovin cocktaileiksi.
                          Tilanne Raappanan rintamaosan pohjoispuolella oli vakavampi. Neuvostoliiton 54. divisioona pääsi Kuhmon kohdalla pitkälle Suomen puolelle. Lieksan kohdalla kaikki neuvostojoukkojen hyökkäykset lyötiin takaisin rajan taakse. Kun tilanne rauhoittui, Raappana joutui lähettämään joukkojaan Suomussalmelle ja Kuhmoon.
                          Rauha solmittiin 12. maaliskuuta 1940.
                  </p>
                      </MDBTabPane>
                      <MDBTabPane tabId="3">
                        <p style={styles.teksti}>
                          Suomi saavutti jatkosodan viimeiset merkittävät voittonsa Ilomantsissa heinä-elokuun vaihteessa 1944. Puna-armeijan hyökkäykset
                          Karjalankannaksella ja Aunuksessa olivat tuolloin jo rauhoittuneet. Mutta Ilomantsin suunnassa Neuvostoliiton 176. ja 289. divisioonat
                          olivat edenneet huolestuttavan pitkälle. Hyökkäys uhkasi koko II armeijakunnan vasenta sivustaa.

                          Kenraalimajuri Raappana sai käskyn siirtyä Ilomantsiin ja perustaa Ryhmä Raappanan vihollisen lyömiseksi. Raappanalle alistettiin viivytystaistelua
                          käyvät 21. jalkaväkiprikaati ja Rajavartiopataljoona 3 sekä täydennyksenä Karjalankannakselta siirretty Ratsuväkiprikaati (Rv.Pr). Lisäksi Raappana
                          päätti käyttää osia omasta divisioonastaan everstiluutnantti Väinö Partisen johdolla. Raappana tunsi alueen kuin omat taskunsa ja antoi vihollisen 289.
                          divisioonan edetä Kuolismaalta Ilomantsiin johtavaa tietä pitkin kapealle Konnukkajärven kannakselle, johon se pysäytettiin. Samanaikaisesti divisioonan
                          selustayhteydet katkaistiin Luovejoella. Perässä tuleva 176. divisioona saarrettiin siten, että Partisen osasto P katkaisi venäläisten pakotien hyökkäämällä
                          pohjoisesta.

                          Vihollinen lähetti motitettujen divisiooniensa avuksi merijalkaväkeä, mutta turhaan. 9. elokuuta mennessä ryhmä Raappana oli tuhonnut 289. divisioonan
                          miltei kokonaan. 176. divisioonan osa onnistui murtautumaan itään. Merijalkaväen pataljoonat kärsivät huomattavia tappioita. Taistelulla oli suuri merkitys psykologisesti, olihan juuri jouduttu luovuttamaan Aunus ja Karjalankannas puna-armeijan painostuksen edessä. Raappanalle esitettiin näistä taisteluista Mannerheim-ristiä toisen kerran, mutta ylipäällikkö ei hyväksynyt tätä esitystä. Ei ole syytä väheksyä vihollista sillä vihollinen murtautui moteista ulos tosin jättäen raskaan kalustonsa sinne, mutta vihollinen oli ottanut oppia suomalalaisilta pitkän sodan saatossa. Tosin miehistöhukkakin oli huomattava.
                          Sodan jälkeen valvontakomissio painosti Suomen johtoa ja niinpä Raappana passitettiin eläkkeelle.
                  </p>
                      </MDBTabPane>
                    </MDBTabContent>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      Lähteet
      </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>https://jput.fi/Ilomantsin_torjuntavoitto_1944.htm
                      <br></br>https://fi.wikipedia.org/wiki/Erkki_Raappana</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Kuva: JHLcolorizing, sa-kuva</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src="../kuvat/pienikuva1.jpg" />
            <Card.Body>
              <Card.Title>Ilomantsin Ontronvaaraan 7.7.1941 hyökänneet rajamiehet </Card.Title>
              <Card.Text>
                Kuva otettu 8.7.1941 Teppanassa (SA-kuva) <p></p>
                Ilomantsissa toimi majuri Viljo Kivikon johtama 24. Rajakomppania.
                Joukkueiden johtajina Ilomantsissa toimivat luutnantit Aatto Mäkinen ja Eino Siippola.
                Ilomantsin rajakomppania koki suuria menetyksiä Ontronvaaran kylän väkivaltaisessa tiedustelussa 7.7.1941.
                Rajamiehet yrittivät vallata Ontronvaaran kylän noin 150 rajamiehen ja Hämeen Ratsurykmentin ratsumiesten voimin.
                Ontronvaaraa oli puolustamassa noin 600 eversti Vallin neuvostosotilasta.
                Puolustajat olivat asemissa hyvin rakennetuissa taisteluhaudoissa ja betonilla vahvistetuissa bunkkereissa.
                Taistelusta tuli verinen ja siinä kaatui seitsemän ilomantsilaista rajamiestä ja heidän lisäksi useita rakuunoita.
                Taistelussa haavoittui 40 sotilasta. Kivikon osaston miehet perääntyivät seuraavana yönä takaisin Koitajoen takana olleisiin asemiin.
                Tästäkin taistelusta on yksityiskohtainen kertomus Itärajan Korpisoturit kirjassa.<br></br>{' '}<br></br>
                <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Lähteet
      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>https://enskakettunen.blogspot.com/2018/
                        <br></br>https://sa-kuva.fi/
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Text>

            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Kuva: SA-kuva</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src="https://i.gyazo.com/b67a2a0878764e7e29f1f7f54b133efc.png" />
            <Card.Body>
              <Card.Title>Lauri Törni</Card.Title>
              <Card.Text>
              Törni oli kolmen maan armeijassa palvellut suomalainen sotilas ja Mannerheim-ristin ritari. Hän palveli Suomen armeijassa kapteenina, kansallissosialistisen Saksan Waffen-SS-joukoissa 1941 Untersturmführerinä ja 1945 Hauptsturmführerinä (kapteeni) sekä Yhdysvaltojen armeijassa kapteenina vuodesta 1960. Näin Törni tuli elämänsä aikana saavuttaneeksi taistelukentillä kolmen eri maan armeijassa kapteenin arvon. Hän kuoli helikopterionnettomuudessa Vietnamin sodassa ja hänet ylennettiin kuolemansa jälkeen postuumisti Yhdysvaltain armeijan majuriksi.

United States Army Special Forces nimesi Törnin vuonna 2010 1. rykmentin kunniajäseneksi. Joulukuussa 2006 Törni valittiin Suomen Sotilas -lehden lukijaäänestyksessä rohkeimmaksi Mannerheim-ristin ritareista.
      </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Kuva: SA-Kuva</small>
            </Card.Footer>
          </Card>
        </CardDeck>

      </div>
    );
  }
}

export default Avainhenkilot;