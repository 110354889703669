import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container'
import { GoodreadsBookshelf } from "react-goodreads-shelf";

//todo
class Goodreads extends React.Component {

    render() {
        return (
            <div className="text-center">
            <br></br>
<Container className="text-center">
<h1>Viimeisimmät lukemani kirjat:</h1>
<GoodreadsBookshelf userId="118993237" />
<b></b>
<h1>Seuraavina lukulistalla:</h1>
<GoodreadsBookshelf userId="118993237" shelf="to-read" />
<b></b>
<h1>Suosikit:</h1>
<GoodreadsBookshelf userId="118993237" shelf="favorites" />
</Container>
          </div>
        );
    }
}

export default Goodreads;