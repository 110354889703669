// tarvittavat react -importit sekä komponenttien ja näkymien importit
import React from 'react';
import './index.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NavPalkki from './components/NavPalkki';
import KotiSivu from './screens/KotiSivu';
import Galleria from './screens/Galleria';
import Sijainnit from './screens/Sijainnit';
import Palaute from './screens/Palaute';
import Goodreads from './screens/Goodreads';
import Avainhenkilot from './screens/Avainhenkilot';
import 'bootstrap/dist/css/bootstrap.min.css';
// © 2021 Isto Päivinen (1905308)
// rakenne ja palkin reititykset
function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <NavPalkki />
          <Switch>
          <Route path="/avainhenkilot" component={Avainhenkilot} /> 
          <Route path="/galleria" component={Galleria} /> 
          <Route path="/sijainnit" component={Sijainnit} /> 
          <Route path="/yhteystiedot" component={Palaute} /> 
          <Route path="/Goodreads" component={Goodreads} /> 
            <Route path="/" component={KotiSivu} />
          </Switch>
        </div>
      </Router>

    </div>
  );
}

export default App;