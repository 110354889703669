import React from 'react';
import { Jumbotron as Jumbo, Container, Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';
import taustaKuva from '../kuvat/isokuva2.jpg';

const Styles = styled.div`
  .jumbo {
    background: url(${taustaKuva}) no-repeat fixed bottom;
    background-size: cover;
    color: #efefef;
    height: "auto";
    position: relative;
    z-index: -2;
  }
  .overlay {
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`;


export const SijainnitSivu = () => (
  
  <Styles>
    <Jumbo fluid className="jumbo">
      <div className="overlay"></div>
      <Container>
  <Row>
    <Col>
    <h2>Sijainnit kartalla:</h2>
    Venäjän puna-armeija teki viimeisen suurhyökkäyksen Ilomantsin suunnassa heinä-elokuun vaiheessa vuonna 1944 alun alkaen kahden divisioonan (176. ja 289. D) voimin. Puna-armeija yritti vallata Ilomantsin tärkeän tienristeyksen ja edetä sieltä Suomen VI AK (armeijakunta) selustaan, joka oli pysäyttänyt puna-armeijan hyökkäyksen mm. Nietjärvellä saavuttaen loistavan torjuntavoiton.
    Ilomantsin taistelualue oli laaja, noin 40 kilometriä leveä ja noin 30 kilometriä syvä. Maasto-oloilla tuli
olemaan huomattava merkitys sotatoimille. Alueen koko ei tarkoittanut, että suomalaisilla johtoportailla
olisi ollut kaikkialla toimintavapaus. 
<br></br><p></p>
    <Image src='../kuvat/karttakuva3.jpg' fluid />
    <br></br>
    <p></p>
    Tilan puute oli pahin Ilajanjärven pohjoispäässä, siellä, missä
vastustajan kärkiosat olivat lähimpänä ensimmäistä todennäköistä tavoitettaan – joka oli muuten
Korentovaarasta vain 5 kilometrin päässä. Nojautuen pitkäaikaiseen kokemukseensa erämaataisteluista
Raappana oli sitä mieltä, että mitä lännemmäksi venäläiset korvessa etenivät, sitä helpompi heidät oli
tuhota ja saada aikaan ratkaisu. Erämaaolot uuvuttaisivat hyökkääjää, ja sen huolto joutuisi vaikeuksiin
huoltoyhteyksien pidentyessä. Näin ollen Raappanan ajatuksena oli houkutella vastustajan voimat syvälle
raskas kalusto perässään, jotta ne saataisiin epäedulliseen maastoon.
Alla olevalta kartalta näkyy tilanne 1.8.1944.
<p></p>
    <Image src='../kuvat/karttakuva2.jpg' fluid />
    <p className ="text-muted">
    Matti Koskimaa, Suomen kohtalon ratkaisut, 2013, sivu 495
    </p>
</Col>
  </Row>
  <Row>
    <Col>
    Nyt puna-armeija yritti vallata Ilomantsin tärkeän tienristeyksen ja edetä sieltä Suomen VI AK (armeijakunta) selustaan, 
joka oli pysäyttänyt puna-armeijan hyökkäyksen mm. Nietjärvellä saavuttaen loistavan torjuntavoiton.
Raappanan johtamat tilapäisjoukot - viivytystaisteluissa kulunut ja väsynyt 21. Pr (noin 7 000 miestä) ja Karjalan Kannakselta apuun siirretty levännyt ja täydennetty Ratsuväkiprikaati (Rv.Pr, noin 6 000 miestä) kävivät rohkeaan kaksinkertaiseen kaksipuoliseen saarrostukseen perustuvaan vastahyökkäykseen tehden ensin molemmista divisioonista omat mottinsa Leppävaaran-Lutikkavaaran ja Vellivaaran-Lehmivaaran alueille ja pilkkoen sekä tuhoten sitten näitä divisioonia.

Alhaalla olevalla kartalla näkyy tilanne 7.8.1944.
<p></p>
    <Image src='../kuvat/karttakuva1.jpg' fluid />
    <p className ="text-muted">
    Matti Koskimaa, Suomen kohtalon ratkaisut, 2013, sivu 502
    </p>
    Samanaikaisesti idästä yritti tulla motitetuille puna-armeijan divisioonille apuun kolme merijalkaväkiprikaatia (69., 70. ja 3.), sekä panssariprikaati ja pioneeriprikaati, nämä kuitenkin torjuttiin.

Lopulta venäläiset näkivät tilanteensa toivottomaksi ja divisioonien henkilöstön rippeet tekivät läpimurron itään (eli pakenivat taistelukentältä). Divisioonien raskas kalusto jäi suomalaisten sotasaaliiksi (mm. yli 100 tykkiä ja lähes sata kranaatinheitintä), ja taistelukenttä jäi taistelun voittaneiden suomalaisten haltuun.

Eversti Matti Koskimaan ansiokkaan kirjan mukaan suomalaiset saivat Ilomantsin sotatoimissa seuraavanlaisen sotasaaliin: Kuusi panssarivaunua, 51 kenttätykkiä, 89 kranaatinheitintä, 55 panssarintorjuntatykkiä, 43 autoa, 33 kenttäradiota, 48 kenttäkeitintä ja 333 hevosta.
<p></p>
Toki olisi ollut vieläkin parempi jos kaikki vihollisjoukot olisi saatu tuhottua, mutta korpitaistelussa vähäisillä joukoilla oli mahdotonta saada aikaan lujaa piiritysrengasta. Vihollinen löysi heikkoja kohtia, joista pääsi juoksemaan karkuun kun jätti raskaan kaluston suomalaisille sotasaaliiksi.

Ryhmä Raappanan tykistö ampui kymmenen vuorokauden aikana 36 000 kranaattia ja venäläinen tykistö noin 10 000 kranaattia. Venäläisen tykistön vähäiseen tulitoimintaan oli syynä mm. suomalaisten sissien suorittamat ammuskolonnien tuhoamiset. Esimerkiksi Mannerheim-ristin ritari, luutnantti Heikki Nykäsen johtama sissiosasto tuhosi 30 kuorma-auton kolonnan, joka oli kuljettamassa tykistön ammuksia.

Suomalaisen lähteen mukaan: ”Neuvostoliittolaisten arvioidut tappiot olivat 3200 kaatunutta ja monituhantinen haavoittuneiden joukko. Suomalaisten tappiot olivat kaatuneina ja kadonneina lähes 400 sekä haavoittuneina 1300.”

Venäläisen lähteen (professori Juri Kilin) mukaan: ”Ilomantsin mottitaistelun osallistuneiden yhtymien (289 ja 176.JvD, 3, 69 ja 70 MjvPr) kokonaistappiot 1-11.8. välisenä aikana olivat esikuntien antamien tietojen mukaan noin 5 000 miestä, joista noin 1 550 kaatunutta ja kadonnutta.”

Tappioiden ero selittyy osittain sillä, että suurtaistelua käytiin pari päivää jo heinäkuun puolella ja venäläinen tappiotieto koskee vain elokuuta.
</Col>
  </Row>
  <br></br>
  <h2>Historialliset nähtävyydet</h2>
  <h3>Taistelijan talo</h3>
  Taistelijan Talo (1988) on rakennettu kunnianosoitukseksi sotiemme veteraaneille. Rakennukset, näyttelyt ja esineistö kertovat sotiemme ajasta sekä siitä uhrautuvasta työstä ja taistelusta, jota sota-ajan ihmiset joutuivat itsenäisyytemme säilyttämiseksi tekemään.
Taistelijan Talolla on esillä sotahistoriallisia näyttelyjä sisä- ja ulkotiloissa. <br>
</br>Osoite: Hatuntie 387 A, 82967 HATTU
<br></br>http://www.taistelijantalo.fi/
</Container>
    </Jumbo>
  </Styles>
)