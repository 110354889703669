import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

// bootstrapin navigointipalkki
const NavPalkki = () => {
  return (
<Navbar bg="dark" variant="dark" style={{position: "sticky", fontSize: 20}} fixed="top" collapseOnSelect expand="lg">
  <Navbar.Brand href="/">Ilomantsi 1944</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
  <Nav className="mr-auto">
  <Nav.Link href="/avainhenkilot">Avainhenkilöt</Nav.Link>
  <Nav.Link href="/galleria">Galleria</Nav.Link>
  <Nav.Link href="/sijainnit">Sijainnit</Nav.Link>
  <Nav.Link href="/yhteystiedot">Yhteystiedot</Nav.Link>
  <Nav.Link href="/goodreads">Goodreads</Nav.Link>
  </Nav>
  </Navbar.Collapse>
</Navbar>
  );
};

export default NavPalkki;