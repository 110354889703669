import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Jumbotron } from '../components/Jumbotron';

class KotiSivu extends React.Component {

    render() {
        return (
            <div className="align-middle">
                <Jumbotron />
            </div>
        );
    }
}

export default KotiSivu;