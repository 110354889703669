import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { SijainnitSivu } from '../components/SijainnitSivu';
import ReactPlayer from 'react-player'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Sijainnit extends React.Component {

    render() {
        return (
            <div className="align-middle">
                <SijainnitSivu></SijainnitSivu>
                <Container>
  <Row>
    <Col></Col>
    <Col>
    <Accordion defaultActiveKey="1">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        Katso video:
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body><ReactPlayer controls={true} url='https://www.youtube.com/watch?v=AQjLJem3IGQ' /></Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
    </Col>
    <Col></Col>
  </Row>
  <br></br><br></br><br></br>
</Container>
            </div>
        );
    }
}

export default Sijainnit;