import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import Card from 'react-bootstrap/Card'
import taustaKuva from '../kuvat/taustakuva.jpg';
import { MDBBtn } from "mdbreact";
import Carousel from 'react-bootstrap/Carousel'
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
  isMobile
} from "react-device-detect";
 
var wWidth = '70%';
if(isMobile) {
  wWidth = '95%'
} 
const images = [
  '../kuvat/isokuva1.jpg',
  '../kuvat/kuva1.png',
  '../kuvat/taustakuva.jpg',
  '../kuvat/pienikuva1.jpg',
  '../kuvat/pienikuva2.jpg',
  '../kuvat/karttakuva1.jpg',
  '../kuvat/karttakuva2.jpg',
  '../kuvat/karttakuva3.jpg',
];

const styles = {
  grid: {
      paddingLeft: 5,
      paddingRight:5,
      marginBottom: 55
  },
  row: {
    //  marginLeft: 125,
      margin: 'auto',
   //   height: '50%',
      width: `${wWidth}`,
      marginTop: 10,
     // marginRight: 125,
      padding: 0
  },
  image: {
    //  marginLeft: 125,
    margin: 'auto',
    marginTop: 10,
   //  height: '50%',
  //   width: '90%',
     // width: '150%'
  },
  bg: {
      backgroundImage: `url(${taustaKuva})`,
      backgroundSize: 'cover',
      backgroundColor: 'grey',
      height: 'auto',
      position: 'relative',
      zIndex: '-2'
  }
};
//     <ReactPlayer style={styles.row} url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
const captions = [
  'test',
  'test2',
  'test3',
];

 
export default class LightboxExample extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      open: false,
      setOpen: false,
      photoIndex: 0,
      isOpen: false,
    };
  }
 
  render() {
    const { photoIndex, isOpen } = this.state;
 
    return (
      <div style={styles.row}>
        <style>{'body { background-color: grey; }'}</style>
<Card className="text-center" style={styles.row}>
  <Card.Body>
    <Carousel>
  <Carousel.Item>
    <img 
      className="d-block w-100"
      src="https://i.gyazo.com/214c5a810fd13aef67cbd22e25766504.png"
      alt="First slide"
    />
    <Carousel.Caption>
      <h3>Suontaan sissit</h3>
      <p>Otettu Ilomantsin Hattuvaaran takaa, Hullariin johtavalta tieltä</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="https://i.gyazo.com/f0817876f2f26a41834b755e0ede25c1.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>Raappanat</h3>
      <p>Erkki Raappana ja hänen poikansa, Ermo Raappana</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="https://i.gyazo.com/8e0d7a1953c869f0f448a462f1f11593.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>Rajan ylitys</h3>
      <p>Suomalaisjoukot ylittämässä Tarton rauhan (1920) rajaa.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
 <small className="text-muted">Katso kaikki kuvat täydessä koossa</small>
      <br></br>
      <MDBBtn color="primary" type="button" onClick={() => this.setState({ isOpen: true })} >Avaa galleria</MDBBtn>
    <Card.Text>
    </Card.Text>
  </Card.Body>
  <Card.Footer className="text-muted">Kuvat: SA-kuva, JHLcolorizing
  </Card.Footer>
</Card>

        {isOpen && (
          <Lightbox 
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            imageCaption={captions[this.state.index]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}